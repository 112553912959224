<template>
  <a-modal
    v-model="modalShow"
    :footer="null"
    :width="width"
    class="modal"
    :maskClosable="false"
    :closable="closable"
    @cancel="cancel"
    :title="title"
  >
    <a-form :form="form" @submit="handleSubmit">
      <div class="modal__container">
        <slot name="body" />
      </div>
      <div class="block block__right block__align-center" v-if="!hideButton">
        <div>
          <Button @click="cancel" v-if="showCancel" :text="cancelText[language]" size="small" />
        </div>
        <div class="component__margin-left">
          <Button type="primary" html-type="submit" :text="okText[language]" size="small" />
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import Button from "@/components/button/Button.vue";

export default {
  name: "ModalForm",
  data() {
    return {
      form: this.$form.createForm(this, { name: this.name }),
      modalShow: false,
    };
  },
  props: {
    show: Boolean,
    closable: {
      type: Boolean,
      default: true,
    },
    title: String,
    name: {
      type: String,
      default: "modal",
    },
    okText: {
      type: Object,
      default() {
        return {
          ru: "Добавить",
          en: "Add",
        };
      },
    },
    ok: {
      type: Function,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
    },
    cancelText: {
      type: Object,
      default() {
        return {
          ru: "Отменить",
          en: "Cancel",
        };
      },
    },
  },
  watch: {
    show: function (val) {
      this.modalShow = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.ok) {
            this.ok(values, this.form)
              .then((item) => {
                if (!item.notClose) {
                  this.$emit("ok", values);
                  this.cancel();
                }
              })
              .catch(() => {});
          } else {
            this.$emit("ok", values);
            this.cancel();
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel");
      this.modalShow = false;
    },
  },
  mounted() {
    this.$emit("setForm", this.form);
  },
  created() {
    this.modalShow = this.show;
  },
  components: {
    Button,
  },
};
</script>
